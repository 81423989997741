export function generateUUID(): string {
  // Public Domain/MIT
  if (typeof crypto !== 'undefined' && crypto.randomUUID) {
    return crypto.randomUUID();
  }
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}


import "./styles.css";
import gigachat from "gigachat";

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

const res = await gigachat.init();
console.log('res', res)

// create a button and if clicked, run btnClick
const btn = document.createElement("button");
btn.innerText = "whats the time?";
btn.addEventListener("click", btnClick);
output.appendChild(btn);

async function btnClick() {
  const messageId = generateUUID();  // Generate a unique message ID

  gigachat.shell.ai.completions([
    {
      role: 'user',
      content: 'whats the time?'
    }
  ], messageId)  // Pass the message ID here if your SDK functions expect it

  // Depending on how your SDK is set up, you might need to listen for the new messages here
  // and append them to `output`.
}

// create a button and if clicked, run btnClick
const btn2 = document.createElement("button");
btn2.innerText = "whats the weather?";
btn2.addEventListener("click", btnClick2);
output.appendChild(btn2);

async function btnClick2() {
  const messageId = generateUUID();  // Generate a unique message ID

  gigachat.shell.ai.completions([
    {
      role: 'user',
      content: 'whats the weather'
    }
  ], messageId)  // Pass the message ID here if your SDK functions expect it

  // Depending on how your SDK is set up, you might need to listen for the new messages here
  // and append them to `output`.
}

// create a button and if clicked, run btnClick
const btn3 = document.createElement("button");
btn3.innerText = "create a cloudflare worker that returns json";
btn3.addEventListener("click", btnClick3);
output.appendChild(btn3);

async function btnClick3() {
  const messageId = generateUUID();  // Generate a unique message ID

  gigachat.shell.ai.completions([
    {
      role: 'user',
      content: 'create a cloudflare worker that returns json'
    }
  ], messageId)  // Pass the message ID here if your SDK functions expect it

  // Depending on how your SDK is set up, you might need to listen for the new messages here
  // and append them to `output`.
}